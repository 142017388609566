import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { handleDateObjectConversion } from '../../utils/timeConversion';
import AdminPanelTabs from './AdminPanelTabs';
import Paginator from '../UI/Paginator';

const Users = () => {
 const [users, setUsers] = useState([]);
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(10);
 const [pageCount, setPageCount] = useState(1);

 const getUsers = () => {
  axios
   .get(`/api/get-users?page=${page}&perPage=${perPage}`)
   .then(response => {
    setUsers(response.data.data);
    setPage(response.data.current_page);
    setPageCount(response.data.last_page);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 useEffect(() => {
  getUsers();
 }, [page, perPage]);

 return (
  <div className='container flex-1 px-6 mx-auto max-w-7xl'>
   <AdminPanelTabs />
   <div className='flex flex-col'>
    <div className='w-100 sm:-mx-6 lg:-mx-8'>
     <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-2'>
           ID
          </th>
          <th scope='col' className='px-6 py-2'>
           Ім‘я
          </th>
          <th scope='col' className='px-6 py-2'>
           Компанія
          </th>
          <th scope='col' className='px-6 py-2'>
           Телефон
          </th>
          <th scope='col' className='px-6 py-2'>
           Пошта
          </th>
          <th scope='col' className='px-6 py-2'>
           Створено
          </th>
          <th scope='col' className='px-6 py-2'>
           Оновлено
          </th>
          <th scope='col' className='px-6 py-2'>
           Дії
          </th>
         </tr>
        </thead>
        <tbody>
         {users.map(user => (
          <tr
           key={user.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='whitespace-nowrap px-6 py-2 font-medium'>
            {user.id}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>{user.name}</td>
           <td className='whitespace-nowrap px-6 py-2'>{user.company}</td>
           <td className='whitespace-nowrap px-6 py-2'>{user.phone}</td>
           <td className='whitespace-nowrap px-6 py-2'>{user.email}</td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(user.created_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(user.updated_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2 flex'>
            <TrashIcon className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out' />
            <PencilSquareIcon className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out' />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
      </div>
     </div>
    </div>
   </div>
   <Paginator
    pageCount={pageCount}
    setPage={setPage}
    perPage={perPage}
    setPerPage={setPerPage}
   />
  </div>
 );
};

export default Users;
