import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import TablePreloader from '../UI/TablePreloader';
import CalculationsTable from './CalculationsTable';
import { useSelector } from 'react-redux';

const CompanyCalculation = () => {
 const [companyCalculations, setCompanyCalculations] = useState([]);
 const companyIdentifier = useSelector(
  state => state.users.currentUser.companyIdentifier,
 );
 const [isLoading, setIsLoading] = useState(false);

 const fetchCompanyCalculations = () => {
  setIsLoading(true);
  axios
   .get(`/api/calculations/${companyIdentifier}`)
   .then(response => {
    if (response.data) {
     setCompanyCalculations(response.data);
     setIsLoading(false);
    }
   })
   .catch(error => {
    console.log(error);
    setIsLoading(false);
   });
 };

 useEffect(() => {
  fetchCompanyCalculations();
 }, []);

 return (
  <div className='container px-3 mx-auto max-w-7xl'>
   <h1 className='text-xl font-bold mt-6 mb-4'>Калькуляції</h1>
   <div className='flex flex-col'>
    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
     <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
      <div className='overflow-hidden'>
       {!isLoading ? (
        <CalculationsTable companyCalculations={companyCalculations} />
       ) : (
        <TablePreloader />
       )}
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default CompanyCalculation;
