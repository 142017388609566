import React, { useEffect, useState } from 'react';
import {
 MDBTabs,
 MDBTabsItem,
 MDBTabsLink,
 MDBTabsPane,
} from 'mdb-react-ui-kit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const AdminPanelTabs = () => {
 const [fillActive, setFillActive] = useState('orders');
 const navigate = useNavigate();
 const location = useLocation();

 useEffect(() => {
  const pathname = location.pathname;
  const lastPart = pathname
   .split('/')
   .filter(part => part)
   .pop();
  setFillActive(lastPart);
 }, []);

 return (
  <div>
   <h1 className='text-xl font-bold mb-4'>Панель адміністратора</h1>
   <div className='flex mb-3'>
    <MDBTabs fill className='mb-3'>
     <MDBTabsItem>
      <MDBTabsLink
       onClick={() => {
        navigate('/admin-panel/orders');
        setFillActive('orders');
       }}
       active={fillActive === 'orders'}
      >
       Розрахунки
      </MDBTabsLink>
     </MDBTabsItem>
     <MDBTabsItem>
      <MDBTabsLink
       onClick={() => {
        navigate('/admin-panel/calculations');
        setFillActive('calculations');
       }}
       active={fillActive === 'calculations'}
      >
       Послуги
      </MDBTabsLink>
     </MDBTabsItem>
     <MDBTabsItem>
      <MDBTabsLink
       onClick={() => {
        navigate('/admin-panel/users');
        setFillActive('users');
       }}
       active={fillActive === 'users'}
      >
       Користувачі
      </MDBTabsLink>
     </MDBTabsItem>
     <MDBTabsItem>
      <MDBTabsLink
       onClick={() => {
        navigate('/admin-panel/templates');
        setFillActive('templates');
       }}
       active={fillActive === 'templates'}
      >
       Шаблони
      </MDBTabsLink>
     </MDBTabsItem>
     <MDBTabsItem>
      <MDBTabsLink
       onClick={() => {
        navigate('/admin-panel/pills');
        setFillActive('pills');
       }}
       active={fillActive === 'pills'}
      >
       Затратні матеріали
      </MDBTabsLink>
     </MDBTabsItem>
     <MDBTabsItem>
      <MDBTabsLink
       onClick={() => {
        navigate('/admin-panel/measurements');
        setFillActive('measurements');
       }}
       active={fillActive === 'measurements'}
      >
       Одиниці виміру
      </MDBTabsLink>
     </MDBTabsItem>
    </MDBTabs>
   </div>
  </div>
 );
};

export default AdminPanelTabs;
