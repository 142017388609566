import React from 'react';
import { MDBInput, MDBTextArea } from 'mdb-react-ui-kit';
import Selector from './UI/Selector';

const CompanyInfo = ({ companyInfo, changeCompanyInfoHandler }) => {
 const financeFormlList = [
  { label: 'бюджет', value: 1 },
  { label: 'госпрозрахунок', value: 2 },
  { label: 'за рахунок членських внесків', value: 3 },
  { label: 'змішана', value: 4 },
 ];

 return (
  <>
   {companyInfo && (
    <div className='container px-6 mx-auto max-w-7xl'>
     <h1 className='text-xl font-bold mt-6 mb-4'>Дані закладу</h1>
     <div className='grid grid-cols-2 gap-4'>
      <div className='relative mb-3'>
       <MDBInput
        type='text'
        id='companyName'
        label='Повне найменування закладу'
        value={companyInfo.name}
        onChange={e => changeCompanyInfoHandler('name', e.target.value)}
       />
      </div>
      <div className='relative mb-3'>
       <MDBInput
        type='number'
        id='companyCode'
        label='Ідентифікаційний код (по ЕДРПОУ)'
        value={companyInfo.code}
        onChange={e => changeCompanyInfoHandler('code', e.target.value)}
       />
      </div>
      <div className='relative mb-3'>
       <MDBInput
        type='number'
        id='companyNumber'
        label='Розрахунковий рахунок No'
        value={companyInfo.payment_code}
        onChange={e => changeCompanyInfoHandler('payment_code', e.target.value)}
       />
      </div>
      <Selector
       options={financeFormlList}
       placeholder='Форма фінансування'
       value={financeFormlList.find(form => form.value === companyInfo.form)}
       onChange={e => changeCompanyInfoHandler('form', e.value)}
      />
      <div className='relative mb-3'>
       <MDBInput
        type='text'
        id='companyCeo'
        label='ПІБ керівника закладу'
        value={companyInfo.ceo_name}
        onChange={e => changeCompanyInfoHandler('ceo_name', e.target.value)}
       />
      </div>
      <div className='relative mb-3'>
       <MDBInput
        type='text'
        id='companyPhoneNumber'
        label='Службовий телефон керівника'
        value={companyInfo.ceo_phone}
        onChange={e => changeCompanyInfoHandler('ceo_phone', e.target.value)}
       />
      </div>
      <div className='relative mb-3'>
       <MDBInput
        type='text'
        id='companyCounter'
        label='ПІБ головного бухгалтера'
        value={companyInfo.counter_name}
        onChange={e => changeCompanyInfoHandler('counter_name', e.target.value)}
       />
      </div>
      <div className='relative mb-3'>
       <MDBInput
        type='text'
        id='companyNumber'
        label='Службовий телефон бухгалтера'
        value={companyInfo.counter_phone}
        onChange={e =>
         changeCompanyInfoHandler('counter_phone', e.target.value)
        }
       />
      </div>
      <div className='relative mb-3'>
       <MDBTextArea
        id='address'
        rows={4}
        label='Юридична адреса'
        value={companyInfo.address}
        onChange={e => changeCompanyInfoHandler('address', e.target.value)}
       />
      </div>
     </div>
     <h1 className='text-xl font-bold mt-6 mb-4'>Планові витрати</h1>
     <div className='flex flex-col'>
      <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
       <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
        <div className='overflow-hidden'>
         <table className='min-w-full border border-collapse text-center text-sm font-light'>
          <thead className='bg-info-400 text-neutral-800'>
           <tr>
            <th scope='col' className='border bg-blue-100 px-6 py-4'>
             #
            </th>
            <th scope='col' className='border bg-blue-100 px-6 py-4'>
             Найменування витратних статей
            </th>
            <th scope='col' className='border bg-blue-100 px-6 py-2 w-36'>
             Планові витрати , (грн.)
            </th>
           </tr>
          </thead>
          <tbody>
           <tr className='border bg-warning-100'>
            <td className='bg-gray-200 font-bold border whitespace-nowrap px-6 py-4'>
             1
            </td>
            <td
             colSpan='2'
             className='bg-gray-200 font-bold border whitespace-nowrap px-6 py-4'
            >
             ПРЯМІ ВИТРАТИ
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.1</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Фонд оплати праці медичного персоналу (лікарі, середній медичний
             персонал, молодший медичний персонал ), дезінфектор, машиніст з
             прання та ремонту спецодягу, прибиральник виробничих приміщень,
             комплектувальник білизни)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.medical_staff_salary_fund}
               onChange={e =>
                changeCompanyInfoHandler(
                 'medical_staff_salary_fund',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.2</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Фонд оплати праці персоналу, що забезпечує перебування в палатах
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.supply_staff_salary_fund}
               onChange={e =>
                changeCompanyInfoHandler(
                 'supply_staff_salary_fund',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.3</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Фонд оплати праці персоналу, що забезпечує харчування (працівники
             кухонь та мол. медичні сестри годувальниці)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.food_service_staff_salary_fund}
               onChange={e =>
                changeCompanyInfoHandler(
                 'food_service_staff_salary_fund',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.4</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Витрати на перебування в палатах
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.inpatient_stay_costs}
               onChange={e =>
                changeCompanyInfoHandler('inpatient_stay_costs', e.target.value)
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.5</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Витрати на додатковий сервіс для перебування в палатах
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.additional_services_inpatient_stay_costs}
               onChange={e =>
                changeCompanyInfoHandler(
                 'additional_services_inpatient_stay_costs',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.6</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Витрати на харчування (в т.ч. вартість продуктів харчування або
             вартість дієтичних столів, вантажно-розвантажувальні, заготівельні
             послуги та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.food_expenses}
               onChange={e =>
                changeCompanyInfoHandler('food_expenses', e.target.value)
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.7</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Витрати на додатковий сервіс для харчування пацієнтів
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.additional_services_food_patients}
               onChange={e =>
                changeCompanyInfoHandler(
                 'additional_services_food_patients',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1.8</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Інші прямі витрати (витрати на деззасоби, малоцінний та
             швидкозношуваний медичний інвентар, інструментарій та інструменти,
             бланки, амортизація та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.other_direct_expenses}
               onChange={e =>
                changeCompanyInfoHandler(
                 'other_direct_expenses',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='border bg-warning-100'>
            <td className='bg-gray-200 font-bold border whitespace-nowrap px-6 py-4'>
             2
            </td>
            <td
             colSpan='2'
             className='bg-gray-200 font-bold border whitespace-nowrap px-6 py-4'
            >
             ЗАГАЛЬНОВИРОБНИЧІ ВИТРАТИ
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>2.1</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Фонд оплати праці загальновиробничого персоналу (завідувачі
             відділень, старші медсестри, медреєстратори)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.general_staff_salary_fund}
               onChange={e =>
                changeCompanyInfoHandler(
                 'general_staff_salary_fund',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>2.2</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Експлуатаційні витрати (витрати на опалення, освітлення,
             водопостачання, газ, вивіз сміття, дезінфекція, технічне
             обслуговування лічильників та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.operational_expenses}
               onChange={e =>
                changeCompanyInfoHandler('operational_expenses', e.target.value)
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>2.3</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Амортизація загальновиробничого призначення
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.general_depreciation}
               onChange={e =>
                changeCompanyInfoHandler('general_depreciation', e.target.value)
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>2.4</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Інші загальновиробничі витрати (канцелярські витрати,
             електротовари, бланки, витрати на охорону праці, транспортні
             послуги, оренду, проведення поточного ремонту, запчастини, технічне
             обслуговування медтехніки, ліфтів, холодильників, касових апаратів,
             витрати на послуги санстанції та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.other_general_expenses}
               onChange={e =>
                changeCompanyInfoHandler(
                 'other_general_expenses',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='border bg-warning-100'>
            <td className='bg-gray-200 font-bold border whitespace-nowrap px-6 py-4'>
             3
            </td>
            <td
             colSpan='2'
             className='bg-gray-200 font-bold border whitespace-nowrap px-6 py-4'
            >
             АДМІНІСТРАТИВНІ ВИТРАТИ
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>3.1</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Фонд оплати праці адміністративного персоналу (адмінуправління,
             бухгалтерія, планово-економічний відділ, господарчий персонал,
             юрист та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.administrative_staff_salary_fund}
               onChange={e =>
                changeCompanyInfoHandler(
                 'administrative_staff_salary_fund',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>3.2</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Інші адміністративні витрати (витрати на зв'язок, програмне
             забезпечення, пожежну і сторожеву охорону, відомчу передплату,
             представницькі витрати, банківські послуги, аудиторські та
             бухгалтерські послуги, видатки на відрядження апарата управління,
             оплата за курси підвищення кваліфікації, юридичні послуги,
             амортизація та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.other_administrative_expenses}
               onChange={e =>
                changeCompanyInfoHandler(
                 'other_administrative_expenses',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>4</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Створення та розміщення рекламної продукції (планові)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={
                companyInfo.advertising_material_creation_and_placement_budget
               }
               onChange={e =>
                changeCompanyInfoHandler(
                 'advertising_material_creation_and_placement_budget',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
          </tbody>
         </table>
        </div>
       </div>
      </div>
     </div>
     <h1 className='text-lg font-bold font-bold mt-6 mb-4'>
      Інформація по площі закладу
     </h1>
     <div className='flex flex-col'>
      <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
       <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
        <div className='overflow-hidden'>
         <table className='min-w-full border text-center text-sm font-light'>
          <thead className='border-info-200 bg-info-400 text-neutral-800'>
           <tr>
            <th scope='col' className='bg-blue-100 border px-6 py-4'>
             #
            </th>
            <th scope='col' className='bg-blue-100 border px-6 py-4'>
             Показник
            </th>
            <th scope='col' className='bg-blue-100 border px-6 py-4 w-36'>
             Площа (м²)
            </th>
           </tr>
          </thead>
          <tbody>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Площа, що орендується сторонніми організаціями
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.rented_by_external_organizations_area}
               onChange={e =>
                changeCompanyInfoHandler(
                 'rented_by_external_organizations_area',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>2</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Площа приміщень для надання медичних послуг пацієнтів (лабораторії,
             рентген, операційні, реанімація, маніпуляційні, кабінети для
             обстеження, масажу, лікувальних процедур та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.medical_service_rooms_area}
               onChange={e =>
                changeCompanyInfoHandler(
                 'medical_service_rooms_area',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>3</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Площа палат з ліжками для перебування пацієнтів
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.patient_bedwards_area}
               onChange={e =>
                changeCompanyInfoHandler(
                 'patient_bedwards_area',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>4</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Площа приміщень харчоблоку та їдалень
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.kitchen_and_canteen_area}
               onChange={e =>
                changeCompanyInfoHandler(
                 'kitchen_and_canteen_area',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>5</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Інша площа (адміністративні, загальновиробничі, підсобні
             приміщення, санвузли, коридори, ліфти та ін.)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.other_areas}
               onChange={e =>
                changeCompanyInfoHandler('other_areas', e.target.value)
               }
              />
             </div>
            </td>
           </tr>
          </tbody>
         </table>
        </div>
       </div>
      </div>
     </div>
     <h1 className='text-lg font-bold font-bold mt-6 mb-4'>
      Інша інформація по закладу
     </h1>
     <div className='flex flex-col'>
      <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
       <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
        <div className='overflow-hidden'>
         <table className='min-w-full border text-center text-sm font-light'>
          <thead className='border-info-200 bg-info-400 text-neutral-800'>
           <tr>
            <th scope='col' className='bg-blue-100 border px-6 py-4'>
             #
            </th>
            <th scope='col' className='bg-blue-100 border px-6 py-4'>
             Показник
            </th>
            <th scope='col' className='bg-blue-100 border px-6 py-4 w-36'>
             Показники
            </th>
           </tr>
          </thead>
          <tbody>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>1</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Єдиний внесок на загальнообов'язкове державне соціальне страхування
             (%)
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.unified_social_security_contribution_rate}
               onChange={e =>
                changeCompanyInfoHandler(
                 'unified_social_security_contribution_rate',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>2</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Фактична сума на оплату відпусток
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.actual_amount_for_leave_payment}
               onChange={e =>
                changeCompanyInfoHandler(
                 'actual_amount_for_leave_payment',
                 e.target.value,
                )
               }
              />
             </div>
            </td>
           </tr>
           <tr className='dark:border-neutral-500'>
            <td className='border whitespace-nowrap px-6 py-4'>3</td>
            <td className='border text-left overflow-hidden text-overflow-ellipsis px-6 py-3'>
             Кількість ліжок загальна
            </td>
            <td className='border'>
             <div>
              <input
               type='number'
               className='text-lg font-bold w-full px-2  outline-none'
               id='companyNumber'
               placeholder='заповніть'
               value={companyInfo.total_number_of_beds}
               onChange={e =>
                changeCompanyInfoHandler('total_number_of_beds', e.target.value)
               }
              />
             </div>
            </td>
           </tr>
          </tbody>
         </table>
        </div>
       </div>
      </div>
     </div>
    </div>
   )}
  </>
 );
};

export default CompanyInfo;
