import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
 baseURL: process.env.REACT_APP_BASE_URL,
 withCredentials: true,
 withXSRFToken: true,
 xsrfCookieName: 'XSRF-TOKEN',
 xsrfHeaderName: 'X-XSRF-TOKEN',
 headers: {
  // 'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Accept: 'application/json',
 },
});

api.interceptors.response.use(
 response => {
  if (response.status === 200) {
   toast.success(response.data.message);
  }
  return response;
 },
 error => {
  if (error.response) {
   if (error.response.status === 401 || error.response.status === 419) {
    console.log('401 error');
    localStorage.clear();
    window.location.href = '/login';
   }

   if (error.response.data && error.response.data.message) {
    toast.error(error.response.data.message);
   }
  }
  return Promise.reject(error);
 },
);

export default api;
