import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import {
 InformationCircleIcon,
 PencilSquareIcon,
 TrashIcon,
} from '@heroicons/react/24/outline';
import TablePreloader from '../UI/TablePreloader';
import ModalAddMeasurement from './ModalAddMeasurement';
import ModalDeleteMeasurement from './ModalDeleteMeasurement';
import ModalChangeMeasurement from './ModalChangeMeasurement';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { handleDateObjectConversion } from '../../utils/timeConversion';
import Paginator from '../UI/Paginator';

const Measurements = () => {
 const companyIdentifier = useSelector(
  state => state.users.currentUser.companyIdentifier,
 );
 const [companyMeasurements, setCompanyMeasurements] = useState(null);
 const [templateMeasurements, setTemplateMeasurements] = useState([]);
 const [isAddMeasurementModalOpen, setIsAddMeasurementModalOpen] =
  useState(false);
 const [isEditMeasurementModalOpen, setIsEditMeasurementModalOpen] =
  useState(false);
 const [isDeleteMeasurementModalOpen, setIsDeleteMeasurementModalOpen] =
  useState(false);
 const [currentMeasurement, setCurrentMeasurement] = useState({});
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(10);
 const [pageCount, setPageCount] = useState(1);

 const getTemplateMeasurements = () => {
  axios
   .get(`/api/get-measurements`)
   .then(response => {
    setTemplateMeasurements(response.data);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 useEffect(() => {
  getTemplateMeasurements();
 }, []);

 const getCompanyMeasurements = () => {
  axios
   .get(
    `/api/company/${companyIdentifier}/measurements?page=${page}&perPage=${perPage}`,
   )
   .then(response => {
    setCompanyMeasurements(response.data.data);
    setPage(response.data.meta.currentPage);
    setPageCount(response.data.meta.lastPage);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 useEffect(() => {
  getCompanyMeasurements();
 }, []);

 useEffect(() => {
  getCompanyMeasurements();
 }, [page, perPage]);

 const addMeasurementHandler = name => {
  axios
   .post(`/api/company/${companyIdentifier}/measurements`, { name })
   .then(response => {
    if (response.status === 200) {
     setIsAddMeasurementModalOpen(false);
     getCompanyMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const changeMeasurementHandler = name => {
  axios
   .put(
    `/api/company/${companyIdentifier}/measurements/${currentMeasurement.id}`,
    { name },
   )
   .then(response => {
    if (response.status === 200) {
     setIsEditMeasurementModalOpen(false);
     getCompanyMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const deleteMeasurementHandler = () => {
  axios
   .delete(
    `/api/company/${companyIdentifier}/measurements/${currentMeasurement.id}`,
   )
   .then(response => {
    if (response.status === 200) {
     setIsDeleteMeasurementModalOpen(false);
     getCompanyMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 return (
  <div className='container flex flex-col'>
   <div className='w-100 sm:-mx-6 lg:-mx-8'>
    <div className='font-bold ms-2 mb-2'>
     Перелік стандартних одиниць виміру
    </div>
    {templateMeasurements.length > 0 ? (
     <div className='row'>
      <div className='col-12'>
       <div className='w-100 flex flex-wrap'>
        {templateMeasurements.map((measurement, index) => (
         <span
          key={measurement.index}
          className='bg-gray-200 px-2 py-0 m-1 rounded-3'
         >
          {measurement.label}
         </span>
        ))}
       </div>
      </div>
     </div>
    ) : (
     <Skeleton height={24} />
    )}
    <div
     className='flex w-full my-4 rounded-lg bg-orange-100 p-3 text-base'
     role='alert'
    >
     <div className='flex items-center justify-center'>
      <InformationCircleIcon className='w-6 h-6 mr-4' />
     </div>
     <div>
      Якщо є необхідність - ви можете додати власні одиниці виміру. Вони
      з‘являться в списку при розрахунках
     </div>
    </div>
    <div className='inline-block min-w-full'>
     {companyMeasurements ? (
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-2'>
           Одиниця виміру
          </th>
          <th scope='col' className='px-6 py-2'>
           Створено
          </th>
          <th scope='col' className='px-6 py-2'>
           Оновлено
          </th>
         </tr>
        </thead>
        <tbody>
         {companyMeasurements.map(measurement => (
          <tr
           key={measurement.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='whitespace-nowrap px-6 py-2'>{measurement.name}</td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(measurement.created_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(measurement.updated_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2 flex'>
            <TrashIcon
             className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsDeleteMeasurementModalOpen(true);
             }}
            />
            <PencilSquareIcon
             className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsEditMeasurementModalOpen(true);
             }}
            />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
       <div className={`flex justify-end mt-2`}>
        <button
         className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
         onClick={() => setIsAddMeasurementModalOpen(true)}
        >
         Додати
        </button>
       </div>
       <Paginator
        pageCount={pageCount}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
       />
      </div>
     ) : (
      <TablePreloader />
     )}
    </div>
   </div>
   {isAddMeasurementModalOpen && (
    <ModalAddMeasurement
     showModal={isAddMeasurementModalOpen}
     setShowModal={setIsAddMeasurementModalOpen}
     addMeasurementHandler={addMeasurementHandler}
    />
   )}
   {isEditMeasurementModalOpen && (
    <ModalChangeMeasurement
     showModal={isEditMeasurementModalOpen}
     setShowModal={setIsEditMeasurementModalOpen}
     measurementName={currentMeasurement.name}
     changeMeasurementHandler={changeMeasurementHandler}
    />
   )}
   {isDeleteMeasurementModalOpen && (
    <ModalDeleteMeasurement
     showModal={isDeleteMeasurementModalOpen}
     setShowModal={setIsDeleteMeasurementModalOpen}
     measurementName={currentMeasurement.name}
     deleteMeasurementHandler={deleteMeasurementHandler}
    />
   )}
  </div>
 );
};

export default Measurements;
