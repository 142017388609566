const defaultState = {
 identifier: '',
 name: '',
 status: '',
 companyInfo: {},
 calculations: {},
};

const ADD_ORDER = 'ADD_ORDER';
const REMOVE_CALCULATION_ITEM = 'REMOVE_CALCULATION_ITEM';
const CHANGE_CACLULATION_FIRST_LEVEL_VALUE =
 'CHANGE_CACLULATION_FIRST_LEVEL_VALUE';
const CHANGE_CACLULATION_THIRD_LEVEL_VALUE =
 'CHANGE_CACLULATION_THIRD_LEVEL_VALUE';
const ADD_NEW_TIMESPEND = 'ADD_NEW_TIMESPEND';
const ADD_NEW_PILL = 'ADD_NEW_PILL';
const REMOVE_TIMESPEND = 'REMOVE_TIMESPEND';
const REMOVE_PILL = 'REMOVE_PILL';
const CHANGE_COMPANY_INFO = 'CHANGE_COMPANY_INFO';
const CHANGE_CALCULATION_KEY = 'CHANGE_CALCULATION_KEY';
const CHANGE_CALCULATION_PROFIT = 'CHANGE_CALCULATION_PROFIT';
const CHANGE_PILL_INFO_IN_CALCULATION = 'CHANGE_PILL_INFO_IN_CALCULATION';

export const orderReducer = (state = defaultState, action) => {
 const timeSpendObj = {
  level: 1,
  salary: null,
  quality: 'виберіть кваліфікацію',
  category: 1,
  timeSpend: 5,
  workingDay: 8,
  conditionPercent: 0,
 };

 const pillsObj = {
  name: '',
  price: 0,
  spend: 0,
  measurement_type: '',
 };

 switch (action.type) {
  case ADD_ORDER:
   return {
    identifier: action.payload.identifier,
    name: action.payload.name,
    status: action.payload.status,
    companyInfo: action.payload.companyInfo,
    calculations: action.payload.calculations,
   };

  case CHANGE_COMPANY_INFO:
   const [companuInfoKey, companuInfoValue] = action.payload;
   const calculationsCopyForCompanyInfo = JSON.parse(
    JSON.stringify(state.companyInfo),
   );

   calculationsCopyForCompanyInfo[companuInfoKey] = companuInfoValue;

   return { ...state, companyInfo: calculationsCopyForCompanyInfo };

  case CHANGE_CALCULATION_KEY:
   const [
    categoryForCalculationKey,
    calculationIdForCalculationKey,
    calculationKeyForCalculationKey,
    calculationValueForCalculationKey,
   ] = action.payload;
   const calculationsCopyForBonus = JSON.parse(
    JSON.stringify(state.calculations),
   );

   const categoryIndex = calculationsCopyForBonus[
    categoryForCalculationKey
   ].findIndex(
    calculation => calculation.id === calculationIdForCalculationKey,
   );
   calculationsCopyForBonus[categoryForCalculationKey][
    categoryIndex
   ].calculation[calculationKeyForCalculationKey] =
    calculationValueForCalculationKey;

   return { ...state, calculations: calculationsCopyForBonus };

  case CHANGE_CACLULATION_THIRD_LEVEL_VALUE:
   const [category, calculationId, group, secondLevelIndex, key, newValue] =
    action.payload;
   const calc3Copy = JSON.parse(JSON.stringify(state.calculations));

   const categoryThirdLevelIndex = calc3Copy[category].findIndex(
    calculation => calculation.id === calculationId,
   );
   calc3Copy[category][categoryThirdLevelIndex].calculation[group][
    secondLevelIndex
   ][key] = newValue;

   return { ...state, calculations: calc3Copy };

  case ADD_NEW_TIMESPEND:
   const [categoryForTimeSpend, calculationIndex] = action.payload;
   const calculationsCopy = JSON.parse(JSON.stringify(state.calculations));

   const calculationForTimeSpendIndex = calculationsCopy[
    categoryForTimeSpend
   ].findIndex(calculation => calculation.id === calculationIndex);
   calculationsCopy[categoryForTimeSpend][
    calculationForTimeSpendIndex
   ].calculation['stuff'].push(timeSpendObj);

   return { ...state, calculations: calculationsCopy };

  case REMOVE_TIMESPEND:
   const [
    categoryForRemoveTimeSpend,
    itemIndexForRemoveTimeSpend,
    secondLevelIndexForRemoveTimeSpend,
   ] = action.payload;
   const calculationsForRemoveTime = JSON.parse(
    JSON.stringify(state.calculations),
   );

   const calculationIndexForRemoveTimeSpend = calculationsForRemoveTime[
    categoryForRemoveTimeSpend
   ].findIndex(calculation => calculation.id === itemIndexForRemoveTimeSpend);

   calculationsForRemoveTime[categoryForRemoveTimeSpend][
    calculationIndexForRemoveTimeSpend
   ].calculation['stuff'].splice(secondLevelIndexForRemoveTimeSpend, 1);

   return { ...state, calculations: calculationsForRemoveTime };

  case ADD_NEW_PILL:
   const [categoryForPill, firstIndexForPill, secondIndexForPill] =
    action.payload;
   const calculationsForPillCopy = JSON.parse(
    JSON.stringify(state.calculations),
   );

   const calculationForPillIndex = calculationsForPillCopy[
    categoryForPill
   ].findIndex(calculation => calculation.id === firstIndexForPill);
   calculationsForPillCopy[categoryForPill][
    calculationForPillIndex
   ].calculation['pills'].push(pillsObj);

   return { ...state, calculations: calculationsForPillCopy };

  case REMOVE_PILL:
   const [
    categoryForRemovePill,
    itemIndexForRemovePill,
    secondLevelIndexForRemovePill,
   ] = action.payload;
   const calculationsForRemovePill = JSON.parse(
    JSON.stringify(state.calculations),
   );

   const calculationForRemoveIndex = calculationsForRemovePill[
    categoryForRemovePill
   ].findIndex(calculation => calculation.id === itemIndexForRemovePill);

   calculationsForRemovePill[categoryForRemovePill][
    calculationForRemoveIndex
   ].calculation['pills'].splice(secondLevelIndexForRemovePill, 1);

   return { ...state, calculations: calculationsForRemovePill };

  case CHANGE_PILL_INFO_IN_CALCULATION:
   const [id, pillKey, pillValue] = action.payload;
   const calculationsCopyForChangePillInfo = JSON.parse(
    JSON.stringify(state.calculations),
   );

   const categories = Object.keys(calculationsCopyForChangePillInfo);

   for (let i = 0; i < categories.length; i++) {
    calculationsCopyForChangePillInfo[categories[i]].forEach(calculation => {
     calculation.calculation.pills.forEach(pill => {
      if (pill.id === id) {
       pill[pillKey] = pillValue;
       // calculationsCopyForChangePillInfo.orders[categories[i]][procedureIndex].pills[pillIndex][pillKey] = pillValue;
      }
     });
    });
   }

   return { ...state, calculations: calculationsCopyForChangePillInfo };

  case REMOVE_CALCULATION_ITEM:
   return {
    ...state,
    calculations: state.calculations.filter(
     (_, index) => index !== action.payload,
    ),
   };
  case CHANGE_CACLULATION_FIRST_LEVEL_VALUE:
   const [calc1Property, calc1Index, calc1Value] = action.payload;
   const calc1Copy = [...state.calculations];

   calc1Copy[calc1Index][calc1Property] = calc1Value;

   return { ...state, calculations: calc1Copy };
  case CHANGE_CALCULATION_PROFIT:
   const [categoryForProfit, calculationIndexForProfit, valueForProfit] =
    action.payload;
   const calculationsCopyForProfit = JSON.parse(
    JSON.stringify(state.calculations),
   );

   calculationsCopyForProfit.orders[categoryForProfit][
    calculationIndexForProfit
   ].profit = valueForProfit;

   return { ...state, calculations: calculationsCopyForProfit };
  default:
   return state;
 }
};

export const addOrder = payload => ({ type: ADD_ORDER, payload });
export const removeCalculationItem = payload => ({
 type: REMOVE_CALCULATION_ITEM,
 payload,
});
export const changeCalculationFirstLevelValue = payload => ({
 type: CHANGE_CACLULATION_FIRST_LEVEL_VALUE,
 payload,
});
export const changeCalculationThirdLevelValue = payload => ({
 type: CHANGE_CACLULATION_THIRD_LEVEL_VALUE,
 payload,
});
export const addNewTimespend = payload => ({
 type: ADD_NEW_TIMESPEND,
 payload,
});
export const addNewPill = payload => ({ type: ADD_NEW_PILL, payload });
export const removeTimespend = payload => ({
 type: REMOVE_TIMESPEND,
 payload,
});
export const removePill = payload => ({ type: REMOVE_PILL, payload });
export const changeCompanyInfo = payload => ({
 type: CHANGE_COMPANY_INFO,
 payload,
});
export const changeCalculationKey = payload => ({
 type: CHANGE_CALCULATION_KEY,
 payload,
});
export const changeCalculationProfit = payload => ({
 type: CHANGE_CALCULATION_PROFIT,
 payload,
});
export const changePillInfoInCalculation = payload => ({
 type: CHANGE_PILL_INFO_IN_CALCULATION,
 payload,
});
