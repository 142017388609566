import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import 'react-toastify/dist/ReactToastify.css';
import {
 MDBInput,
 MDBModal,
 MDBModalBody,
 MDBModalContent,
 MDBModalDialog,
 MDBModalFooter,
 MDBModalHeader,
 MDBRipple,
} from 'mdb-react-ui-kit';
import Selector from '../UI/Selector';
import Skeleton from 'react-loading-skeleton';

const ModalAddPillsForCompany = ({
 showModal,
 setShowModal,
 addPillsHandler,
}) => {
 const [name, setName] = useState('');
 const [measurement, setMeasurement] = useState(null);
 const [templateMeasurements, setTemplateMeasurements] = useState([]);
 const [price, setPrice] = useState('');
 const [company, setCompany] = useState(null);
 const [companies, setCompanies] = useState('');

 const fetchMeasurements = () => {
  axios
   .get('/api/get-measurements')
   .then(response => {
    setTemplateMeasurements(response.data);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const fetchCompanies = () => {
  axios.get('/api/get-companies-list').then(response => {
   if (response.data) {
    setCompanies(response.data);
   }
  });
 };

 useEffect(() => {
  fetchMeasurements();
  fetchCompanies();
 }, []);

 return (
  <MDBModal open={showModal} onClose={setShowModal}>
   <MDBModalDialog centered>
    <MDBModalContent>
     <MDBModalHeader>
      <h5 className='text-xl font-medium'>Додати затратні матеріали</h5>
      <button
       type='button'
       className='box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none'
       onClick={() => setShowModal(false)}
       aria-label='Close'
      >
       <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='currentColor'
        className='h-6 w-6'
       >
        <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M6 18L18 6M6 6l12 12'
        />
       </svg>
      </button>
     </MDBModalHeader>
     <MDBModalBody>
      <MDBInput
       label='Назва'
       id='name'
       type='text'
       value={name}
       onChange={e => setName(e.target.value)}
      />
      <Selector
       className='mt-3'
       placeholder='Одиниці виміру'
       options={templateMeasurements}
       value={measurement}
       onChange={e => setMeasurement(e)}
      />
      <MDBInput
       className='mt-3'
       label='Ціна за одиницю виміру'
       id='price'
       type='number'
       value={price}
       onChange={e => setPrice(e.target.value)}
      />
      {companies.length > 0 ? (
       <Selector
        options={companies}
        className='mt-2'
        placeholder='Компанія'
        value={company}
        onChange={e => setCompany(e)}
       />
      ) : (
       <Skeleton height={8} />
      )}
     </MDBModalBody>
     <MDBModalFooter>
      <MDBRipple rippleColor='light'>
       <button
        type='button'
        className='inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200'
        onClick={() => setShowModal(false)}
       >
        Скасувати
       </button>
      </MDBRipple>
      <MDBRipple rippleColor='light'>
       <button
        type='button'
        className='ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
        onClick={() =>
         addPillsHandler(company.value, name, measurement.value, price)
        }
       >
        Додати
       </button>
      </MDBRipple>
     </MDBModalFooter>
    </MDBModalContent>
   </MDBModalDialog>
  </MDBModal>
 );
};

export default ModalAddPillsForCompany;
