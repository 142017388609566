import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { getUser } from '../asyncActions/getUser';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MDBCheckbox, MDBInput } from 'mdb-react-ui-kit';
import { addCurrentUserAction } from '../store/usersReducer';

const Registration = () => {
 const [name, setName] = useState('');
 const [company, setCompany] = useState('');
 const [phone, setPhone] = useState('');
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const [passwordConfirm, setPasswordConfirm] = useState('');

 const [errors, setErrors] = useState([]);
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const register = async e => {
  e.preventDefault();
  await axios.get('http://localhost:8000/sanctum/csrf-cookie');
  try {
   await axios
    .post('api/register', {
     name: name,
     company: company,
     phone: phone,
     email: email,
     password: password,
     password_confirmation: passwordConfirm,
    })
    .then(response => {
     axios
      .post('/login', {
       email: email,
       password: password,
      })
      .then(response => {
       if (response.status === 200) {
        dispatch(addCurrentUserAction(response.data));
        navigate('/orders');
       }
      })
      .catch(error => {
       if (error.response && error.response.status === 422) {
        // setAuthError(true);
       } else {
        console.error(error);
       }
      });
    });
  } catch (error) {
   if (error.response) {
    setErrors(error.response);
   } else {
    setErrors('Something goes wrong');
   }
  }
 };

 return (
  <section className='container flex-1 px-6 mx-auto max-w-7xl'>
   <div className='h-full'>
    {/* Left column container with background*/}
    <div className='g-6 flex h-full flex-wrap items-center justify-center lg:justify-between'>
     <div className='shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12'>
      <img
       src='../images/logo_big.svg'
       className='w-full px-6'
       alt='Sample image'
      />
     </div>

     {/* Right column container */}
     <div className='mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12'>
      <form onSubmit={register}>
       {/* Name input */}
       <div className='relative'>
        <MDBInput
         type='text'
         id='nameInput'
         label='Ім‘я'
         value={name}
         required
         onChange={e => setName(e.target.value)}
        />
       </div>
       {errors.name && <p className='text-sm text-red-500'>{errors.name[0]}</p>}

       {/* Email input */}
       <div className='relative mt-6'>
        <MDBInput
         type='email'
         id='emailInput'
         label='Email адреса'
         value={email}
         required
         onChange={e => setEmail(e.target.value)}
        />
       </div>
       {errors.email && (
        <p className='text-sm text-red-500'>{errors.email[0]}</p>
       )}

       {/* Company input */}
       <div className='relative mt-6'>
        <MDBInput
         type='text'
         id='companyInput'
         label='Назва підприємства'
         value={company}
         required
         onChange={e => setCompany(e.target.value)}
        />
       </div>
       {errors.company && (
        <p className='text-sm text-red-500'>{errors.company[0]}</p>
       )}

       <div className='relative mt-6'>
        <MDBInput
         type='number'
         id='phoneInput'
         label='Ваш контактний телефон'
         value={phone}
         required
         onChange={e => setPhone(e.target.value)}
        />
       </div>
       {errors.phone && (
        <p className='text-sm text-red-500'>{errors.phone[0]}</p>
       )}

       {/* Password input */}
       <div className='relative mt-6'>
        <MDBInput
         type='password'
         id='userPwd'
         label='Пароль'
         value={password}
         required
         onChange={e => setPassword(e.target.value)}
        />
       </div>
       {errors.password && (
        <p className='text-sm text-red-500'>{errors.password[0]}</p>
       )}

       {/* Confirm password input */}
       <div className='relative mt-6'>
        <MDBInput
         type='password'
         id='userPwdConfirm'
         label='Повторіть пароль'
         value={passwordConfirm}
         required
         onChange={e => setPasswordConfirm(e.target.value)}
        />
       </div>

       <div className='mt-6 flex items-center justify-between'>
        {/* Remember me checkbox */}
        <div className='mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]'>
         <MDBCheckbox
          name='flexCheck'
          value=''
          id='flexCheckChecked'
          label='Запам‘ятати мене'
          defaultChecked
         />
        </div>
       </div>

       {/* Login button */}
       <div className='text-center lg:text-left'>
        <button
         className='inline-block rounded bg-primary px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
         data-te-ripple-init
         data-te-ripple-color='light'
        >
         Зареєструватись
        </button>

        {/* Register link */}
        <p className='mb-0 mt-2 pt-1 text-sm font-semibold'>
         У вас вже є аккаунт?
         <a
          href='/login'
          className='ml-2 text-danger transition duration-150 ease-in-out hover:text-danger-600 focus:text-danger-600 active:text-danger-700'
         >
          Увійти
         </a>
        </p>
       </div>
      </form>
     </div>
    </div>
   </div>
  </section>
 );
};

export default Registration;
