import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import {
 MDBModal,
 MDBModalBody,
 MDBModalContent,
 MDBModalDialog,
 MDBModalFooter,
 MDBModalHeader,
 MDBRipple,
} from 'mdb-react-ui-kit';

const ModalCalculate = ({ showModal, setShowModal }) => {
 const navigate = useNavigate();
 const userId = useSelector(state => state.users.currentUser.id);
 const order = useSelector(state => state.order);

 const calculate = () => {
  axios
   .post('/api/calculations/calculate', {
    identifier: order.identifier,
    name: order.name,
    calculations: JSON.stringify(order.calculations),
    companyInfo: JSON.stringify(order.companyInfo),
   })
   .then(response => {
    if (response.status === 200) {
     setShowModal(false);
     navigate('/orders');
    }
   })
   .catch(function (error) {
    console.log(error);
    if (error.response.status === 422) {
     toast.error(error.response.data.message);
    }
   });
 };

 return (
  <MDBModal open={showModal} onClose={setShowModal}>
   <MDBModalDialog centered>
    <MDBModalContent>
     <MDBModalHeader>
      {/* <!--Modal title--> */}
      <h5 className='text-xl font-medium'>Розрахувати?</h5>
      {/* <!--Close button--> */}
      <button
       type='button'
       className='box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none'
       onClick={() => setShowModal(false)}
       aria-label='Close'
      >
       <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='currentColor'
        className='h-6 w-6'
       >
        <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M6 18L18 6M6 6l12 12'
        />
       </svg>
      </button>
     </MDBModalHeader>
     {/* <!--Modal body--> */}
     <MDBModalBody>
      <p className='mb-4'>
       Після підтвердження розрахунку ви одразу отримаєте результати, які
       зможете підкоригувати і відправити на підпис.
      </p>
     </MDBModalBody>
     <MDBModalFooter>
      <MDBRipple rippleColor='light'>
       <button
        type='button'
        className='inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200'
        onClick={() => setShowModal(false)}
       >
        Ні. Я його ще підкоригую
       </button>
      </MDBRipple>
      <MDBRipple rippleColor='light'>
       <button
        type='button'
        className='ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
        onClick={calculate}
       >
        Так, пітверджую
       </button>
      </MDBRipple>
     </MDBModalFooter>
    </MDBModalContent>
   </MDBModalDialog>
  </MDBModal>
 );
};

export default ModalCalculate;
