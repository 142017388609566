import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from '../api/axios';

const ForgotPassword = () => {
 const [email, setEmail] = useState('');
 const [errors, setErrors] = useState([]);
 const [status, setStatus] = useState(null);

 const handleSubmit = async e => {
  e.preventDefault();
  await axios.get('http://localhost:8000/sanctum/csrf-cookie');
  setErrors([]);
  setStatus(null);
  try {
   const response = await axios.post('/password/email', { email });
   setStatus(response.data.message);
  } catch (err) {
   if (err.status === 422) {
    setErrors(err.response.data.errors);
   }
  }
 };

 return (
  <section className='container px-6 mx-auto max-w-7xl h-screen'>
   <div className='h-full'>
    <div className='mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 mx-auto'>
     <form onSubmit={handleSubmit}>
      <div className='flex flex-row items-center justify-center lg:justify-start mb-2'>
       <p className='mb-0 mr-4 mb-3 text-lg'>
        Forgot your password? Write your email
       </p>
      </div>
      <div className='relative mb-4' data-te-input-wrapper-init>
       <input
        type='text'
        className='peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0'
        id='emailInput'
        autoComplete='off'
        placeholder='Email address'
        onChange={e => setEmail(e.target.value)}
        value={email}
        required
       />
       <label
        htmlFor='emailInput'
        className='pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[2.15] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[1.15rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[1.15rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary'
       >
        Email address
       </label>
      </div>
      {status && (
       <div className='bg-green-700 mb-2 p-2 rounded text-white'>{status}</div>
      )}
      {errors.email && (
       <p className='text-sm text-red-500'>{errors.email[0]}</p>
      )}
      {/* Login button */}
      <div className='text-center lg:text-left flex justify-center'>
       <button
        className='inline-block rounded bg-primary px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
        data-te-ripple-init
        data-te-ripple-color='light'
       >
        Reset
       </button>
      </div>
     </form>
    </div>
   </div>
  </section>
 );
};

export default ForgotPassword;
