import React, { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import {
 PencilSquareIcon,
 PlusCircleIcon,
 TrashIcon,
} from '@heroicons/react/24/outline';
import TablePreloader from '../../UI/TablePreloader';
import ModalAddMeasurement from '../../Measurements/ModalAddMeasurement';
import ModalDeleteMeasurement from '../../Measurements/ModalDeleteMeasurement';
import ModalChangeMeasurement from '../../Measurements/ModalChangeMeasurement';
import ModalAddMeasurementForCompany from '../../Measurements/ModalAddMeasurementForCompany';
import { handleDateObjectConversion } from '../../../utils/timeConversion';
import AdminPanelTabs from '../AdminPanelTabs';
import Paginator from '../../UI/Paginator';
import ModalAddMeasurementToTemplate from '../../Measurements/ModalAddMeasurementToTemplate';

const AdminMeasurements = () => {
 const [measurements, setMeasurements] = useState([]);
 const [companiesMeasurements, setCompaniesMeasurements] = useState(null);
 const [isAddMeasurementModalOpen, setIsAddMeasurementModalOpen] =
  useState(false);
 const [
  isAddMeasurementForCompanyModalOpen,
  setIsAddMeasurementForCompanyModalOpen,
 ] = useState(false);
 const [isEditMeasurementModalOpen, setIsEditMeasurementModalOpen] =
  useState(false);
 const [isDeleteMeasurementModalOpen, setIsDeleteMeasurementModalOpen] =
  useState(false);
 const [
  isAddMeasurementToTemplateModalOpen,
  setIsAddMeasurementToTemplateModalOpen,
 ] = useState(false);
 const [currentMeasurement, setCurrentMeasurement] = useState({});
 const [pageTemplate, setPageTemplate] = useState(1);
 const [perPageTemplate, setPerPageTemplate] = useState(10);
 const [pageCountTemplate, setPageCountTemplate] = useState(1);
 const [pageCompanies, setPageCompanies] = useState(1);
 const [perPageCompanies, setPerPageCompanies] = useState(10);
 const [pageCountCompanies, setPageCountCompanies] = useState(1);

 const getMeasurements = () => {
  axios
   .get(`/api/measurements?page=${pageTemplate}&perPage=${perPageTemplate}`)
   .then(response => {
    setMeasurements(response.data.data);
    setPageTemplate(response.data.meta.currentPage);
    setPageCountTemplate(response.data.meta.lastPage);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const getCompaniesMeasurements = () => {
  axios
   .get(
    `/api/measurements/companies?page=${pageCompanies}&perPage=${perPageCompanies}`,
   )
   .then(response => {
    setCompaniesMeasurements(response.data.data);
    setPageCompanies(response.data.meta.currentPage);
    setPageCountCompanies(response.data.meta.lastPage);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 useEffect(() => {
  getMeasurements();
  getCompaniesMeasurements();
 }, []);

 useEffect(() => {
  getMeasurements();
 }, [pageTemplate, perPageTemplate]);

 useEffect(() => {
  getCompaniesMeasurements();
 }, [pageCompanies, perPageCompanies]);

 const addMeasurementHandler = name => {
  axios
   .post(`/api/measurements`, { name })
   .then(response => {
    if (response.status === 200) {
     setIsAddMeasurementModalOpen(false);
     getMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const addMeasurementForCompanyHandler = (name, company) => {
  axios
   .post(`/api/company/${company}/measurements`, { name })
   .then(response => {
    if (response.status === 200) {
     setIsAddMeasurementForCompanyModalOpen(false);
     getCompaniesMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const changeMeasurementHandler = name => {
  axios
   .put(`/api/measurements/${currentMeasurement.id}`, { name })
   .then(response => {
    if (response.status === 200) {
     setIsEditMeasurementModalOpen(false);
     getMeasurements();
     getCompaniesMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const deleteMeasurementHandler = () => {
  axios
   .delete(`/api/measurements/${currentMeasurement.id}`)
   .then(response => {
    if (response.status === 200) {
     setIsDeleteMeasurementModalOpen(false);
     getMeasurements();
     getCompaniesMeasurements();
    }
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const addNewMeasurementToTemplateHandler = () => {
  axios
   .post(`/api/measurements`, { name: currentMeasurement.name })
   .then(response => {
    if (response.status === 200) {
     getMeasurements();
     getCompaniesMeasurements();
     setIsAddMeasurementToTemplateModalOpen(false);
    }
   })
   .catch(function (error) {
    setIsAddMeasurementToTemplateModalOpen(false);
    console.log(error);
   });
 };

 return (
  <div className='container flex flex-col'>
   <AdminPanelTabs />
   <div className='w-100 sm:-mx-6 lg:-mx-8'>
    <h5 className='ms-4'>Одиниці виміру з шаблонів</h5>
    <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
     {measurements.length > 0 ? (
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-2'>
           ID
          </th>
          <th scope='col' className='px-6 py-2'>
           Одиниця виміру
          </th>
          <th scope='col' className='px-6 py-2'>
           Створено
          </th>
          <th scope='col' className='px-6 py-2'>
           Оновлено
          </th>
         </tr>
        </thead>
        <tbody>
         {measurements.map(measurement => (
          <tr
           key={measurement.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='whitespace-nowrap px-6 py-2 font-medium'>
            {measurement.id}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>{measurement.name}</td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(measurement.created_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(measurement.updated_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2 flex'>
            <TrashIcon
             className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsDeleteMeasurementModalOpen(true);
             }}
            />
            <PencilSquareIcon
             className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsEditMeasurementModalOpen(true);
             }}
            />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
       <div className={`flex justify-end mt-2`}>
        <button
         className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
         onClick={() => setIsAddMeasurementModalOpen(true)}
        >
         Додати
        </button>
       </div>
       <Paginator
        pageCount={pageCountTemplate}
        setPage={setPageTemplate}
        perPage={perPageTemplate}
        setPerPage={setPerPageTemplate}
       />
      </div>
     ) : (
      <TablePreloader />
     )}
    </div>
   </div>
   <hr />
   <div className='w-100 sm:-mx-6 lg:-mx-8'>
    <h5 className='ms-4'>Одиниці виміру з інших компаній</h5>
    <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
     {companiesMeasurements ? (
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-2'>
           ID
          </th>
          <th scope='col' className='px-6 py-2'>
           Одиниця виміру
          </th>
          <th scope='col' className='px-6 py-2'>
           Автор (компанія)
          </th>
          <th scope='col' className='px-6 py-2'>
           Створено
          </th>
          <th scope='col' className='px-6 py-2'>
           Оновлено
          </th>
         </tr>
        </thead>
        <tbody>
         {companiesMeasurements.map(measurement => (
          <tr
           key={measurement.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='whitespace-nowrap px-6 py-2 font-medium'>
            {measurement.id}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>{measurement.name}</td>
           <td className='whitespace-nowrap px-6 py-2'>
            {measurement.company}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(measurement.created_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(measurement.updated_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2 flex'>
            <TrashIcon
             className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsDeleteMeasurementModalOpen(true);
             }}
            />
            <PencilSquareIcon
             className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsEditMeasurementModalOpen(true);
             }}
            />
            <PlusCircleIcon
             className='ml-2 w-6 h-6 text-primary cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentMeasurement(measurement);
              setIsAddMeasurementToTemplateModalOpen(true);
             }}
            />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
       <div className={`flex justify-end mt-2`}>
        <button
         className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
         onClick={() => setIsAddMeasurementForCompanyModalOpen(true)}
        >
         Додати
        </button>
       </div>
       <Paginator
        pageCount={pageCountCompanies}
        setPage={setPageCompanies}
        perPage={perPageCompanies}
        setPerPage={setPerPageCompanies}
       />
      </div>
     ) : (
      <TablePreloader />
     )}
    </div>
   </div>
   {isAddMeasurementModalOpen && (
    <ModalAddMeasurement
     showModal={isAddMeasurementModalOpen}
     setShowModal={setIsAddMeasurementModalOpen}
     addMeasurementHandler={addMeasurementHandler}
    />
   )}
   {isEditMeasurementModalOpen && (
    <ModalChangeMeasurement
     showModal={isEditMeasurementModalOpen}
     setShowModal={setIsEditMeasurementModalOpen}
     measurementName={currentMeasurement.name}
     changeMeasurementHandler={changeMeasurementHandler}
    />
   )}
   {isDeleteMeasurementModalOpen && (
    <ModalDeleteMeasurement
     showModal={isDeleteMeasurementModalOpen}
     setShowModal={setIsDeleteMeasurementModalOpen}
     measurementName={currentMeasurement.name}
     deleteMeasurementHandler={deleteMeasurementHandler}
    />
   )}
   {isAddMeasurementForCompanyModalOpen && (
    <ModalAddMeasurementForCompany
     showModal={isAddMeasurementForCompanyModalOpen}
     setShowModal={setIsAddMeasurementForCompanyModalOpen}
     addMeasurementForCompanyHandler={addMeasurementForCompanyHandler}
    />
   )}
   {isAddMeasurementToTemplateModalOpen && (
    <ModalAddMeasurementToTemplate
     showModal={isAddMeasurementToTemplateModalOpen}
     setShowModal={setIsAddMeasurementToTemplateModalOpen}
     currentMeasurement={currentMeasurement}
     addNewMeasurementToTemplateHandler={addNewMeasurementToTemplateHandler}
    />
   )}
  </div>
 );
};

export default AdminMeasurements;
