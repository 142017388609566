import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { handleDateObjectConversion } from '../../utils/timeConversion';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import Paginator from '../UI/Paginator';
import { useSelector } from 'react-redux';
import ModalAddPills from './ModalAddPills';
import ModalDeletePill from './ModalDeletePills';
import ModalChangePills from './ModalChangePills';

const Pills = () => {
 const [companyPills, setCompanyPills] = useState([]);
 const [companyPage, setCompanyPage] = useState(1);
 const [companyPerPage, setCompanyPerPage] = useState(10);
 const [companyPageCount, setCompanyPageCount] = useState(1);
 const [isAddPillsModalOpen, setIsAddPillsModalOpen] = useState(false);
 const [isChangePillsModalOpen, setIsChangePillsModalOpen] = useState(false);
 const [isDeletePillsModalOpen, setIsDeletePillsModalOpen] = useState(false);
 const [currentPill, setCurrentPill] = useState({});

 const companyIdentifier = useSelector(
  state => state.users.currentUser.companyIdentifier,
 );

 const fetchCompanyPills = () => {
  axios
   .get(
    `/api/company/${companyIdentifier}/pills?page=${companyPage}&perPage=${companyPerPage}`,
   )
   .then(response => {
    setCompanyPills(response.data.data);
    setCompanyPage(response.data.meta.currentPage);
    setCompanyPageCount(response.data.meta.lastPage);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 useEffect(() => {
  fetchCompanyPills();
 }, [companyPage, companyPerPage]);

 const addPillsHandler = (name, measurementType, price) => {
  axios
   .post(`/api/company/${companyIdentifier}/pills`, {
    name: name,
    measurementType: measurementType,
    price: price,
   })
   .then(response => {
    fetchCompanyPills();
    setIsAddPillsModalOpen(false);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const changePillsHandler = (id, name, measurementType, price) => {
  axios
   .put(`/api/company/${companyIdentifier}/pills/${id}`, {
    name: name,
    measurementType: measurementType,
    price: price,
   })
   .then(response => {
    fetchCompanyPills();
    setIsChangePillsModalOpen(false);
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const deletePillsHandler = id => {
  axios
   .delete(`/api/company/${companyIdentifier}/pills/${id}`)
   .then(response => {
    fetchCompanyPills();
    setIsDeletePillsModalOpen(false);
   })
   .catch(function (error) {
    console.log(error);
    setIsDeletePillsModalOpen(false);
   });
 };

 return (
  <div className='container px-3 mx-auto max-w-7xl'>
   <div className='flex flex-col'>
    <h5 className='ms-4'>Затратні матеріали компанії</h5>
    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
     <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-1'>
           Назва
          </th>
          <th scope='col' className='px-6 py-1'>
           Од.виміру
          </th>
          <th scope='col' className='px-6 py-1'>
           Ціна за од.виміру
          </th>
          <th scope='col' className='px-6 py-1'>
           Створено
          </th>
          <th scope='col' className='px-6 py-1'>
           Оновлено
          </th>
          <th scope='col' className='px-6 py-1'>
           Дії
          </th>
         </tr>
        </thead>
        <tbody>
         {companyPills.map(pill => (
          <tr
           key={pill.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='px-6 py-1 max-w-[400px]'>{pill.name}</td>
           <td className='px-6 py-1'>{pill.measurementType}</td>
           <td className='px-6 py-1'>{pill.price}</td>
           <td className='px-6 py-1'>
            {handleDateObjectConversion(pill.created_at)}
           </td>
           <td className='px-6 py-1'>
            {handleDateObjectConversion(pill.updated_at)}
           </td>
           <td className='px-6 py-1 flex'>
            <TrashIcon
             className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentPill(pill);
              setIsDeletePillsModalOpen(true);
             }}
            />
            <PencilSquareIcon
             className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out'
             onClick={() => {
              setCurrentPill(pill);
              setIsChangePillsModalOpen(true);
             }}
            />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
      </div>
      <div className={`flex justify-end mt-2`}>
       <button
        className='inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
        onClick={() => setIsAddPillsModalOpen(true)}
       >
        Додати
       </button>
      </div>
      <Paginator
       pageCount={companyPageCount}
       setPage={setCompanyPage}
       perPage={companyPerPage}
       setPerPage={setCompanyPerPage}
      />
     </div>
    </div>
   </div>
   {isAddPillsModalOpen && (
    <ModalAddPills
     showModal={isAddPillsModalOpen}
     setShowModal={setIsAddPillsModalOpen}
     addPillsHandler={addPillsHandler}
    />
   )}
   {isChangePillsModalOpen && (
    <ModalChangePills
     showModal={isChangePillsModalOpen}
     setShowModal={setIsChangePillsModalOpen}
     currentPill={currentPill}
     changePillsHandler={changePillsHandler}
    />
   )}
   {isDeletePillsModalOpen && (
    <ModalDeletePill
     showModal={isDeletePillsModalOpen}
     setShowModal={setIsDeletePillsModalOpen}
     currentPill={currentPill}
     deletePillHandler={deletePillsHandler}
    />
   )}
  </div>
 );
};

export default Pills;
