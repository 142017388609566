import './App.css';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Error from './components/Error';
import AuthLayout from './layout/AuthLayout';
import CreateOrder from './components/CreateOrder/CreateOrder';
import OrdersList from './components/OrdersList';
import ChangeOrder from './components/ChangeOrder/ChangeOrder';
import 'react-loading-skeleton/dist/skeleton.css';
import Measurements from './components/Measurements/Measurements';
import Staff from './components/Staff';
import GuestLayout from './layout/GuestLayout';
import Login from './components/Login';
import Registration from './components/Registration';
import Footer from './components/Footer';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CompanyInfoPage from './components/CompanyInfoPage';
import Orders from './components/AdminPanel/Orders';
import Pills from './components/Pills/Pills';
import CompanyCalculation from './components/CalculationList/CompanyCalculation';
import Calculations from './components/AdminPanel/Calculations';
import Users from './components/AdminPanel/Users';
import Templates from './components/AdminPanel/Templates';
import AdminPills from './components/AdminPanel/AdminPills/AdminPills';
import AdminMeasurements from './components/AdminPanel/AdminMeasurements/AdminMeasurements';

function App() {
 return (
  <div className='App'>
   <div className='flex flex-col min-h-screen'>
    <div className='sticky top-0 z-10'>
     <Header />
    </div>
    <div className='flex-grow'>
     <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/forgot-password' element={<ForgotPassword />} />
      <Route exact path='/password/reset/:token' element={<ResetPassword />} />
      <Route path='*' element={<Error />} />

      <Route element={<AuthLayout />}>
       <Route exact path='/orders/create_order' element={<CreateOrder />} />
       <Route exact path='/orders' element={<OrdersList />} />
       <Route
        exact
        path='/orders/create/:identifier'
        element={<ChangeOrder />}
       />
       <Route exact path='/calculations' element={<CompanyCalculation />} />
       <Route exact path='/company' element={<CompanyInfoPage />} />
       <Route exact path='/measurements' element={<Measurements />} />
       <Route exact path='/staff' element={<Staff />} />
       <Route exact path='/pills' element={<Pills />} />
       <Route exact path='/admin-panel/orders' element={<Orders />} />
       <Route
        exact
        path='/admin-panel/calculations'
        element={<Calculations />}
       />
       <Route exact path='/admin-panel/users' element={<Users />} />
       <Route exact path='/admin-panel/templates' element={<Templates />} />
       <Route exact path='/admin-panel/pills' element={<AdminPills />} />
       <Route
        exact
        path='/admin-panel/measurements'
        element={<AdminMeasurements />}
       />
      </Route>

      <Route exact path='/login' element={<Login />} />

      <Route element={<GuestLayout />}>
       <Route exact path='/Registration' element={<Registration />} />
      </Route>
     </Routes>
    </div>
    <div className='mt-auto'>
     <Footer />
    </div>
   </div>
   <ToastContainer />
  </div>
 );
}

export default App;
