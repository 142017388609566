import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
 JsonView,
 allExpanded,
 darkStyles,
 defaultStyles,
 collapseAllNested,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { handleDateObjectConversion } from '../../utils/timeConversion';
import AdminPanelTabs from './AdminPanelTabs';

const Templates = () => {
 const [templates, setTemplates] = useState([]);

 useEffect(() => {
  axios
   .get(`/api/get-templates`)
   .then(response => setTemplates(response.data))
   .catch(function (error) {
    console.log(error);
   });
 }, []);

 return (
  <div className='container flex-1 px-6 mx-auto max-w-7xl'>
   <AdminPanelTabs />
   <div className='flex flex-col'>
    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
     <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-2'>
           ID
          </th>
          <th scope='col' className='px-6 py-2'>
           Категорія
          </th>
          <th scope='col' className='px-6 py-2'>
           Шаблон
          </th>
          <th scope='col' className='px-6 py-2'>
           Створено
          </th>
          <th scope='col' className='px-6 py-2'>
           Оновлено
          </th>
          <th scope='col' className='px-6 py-2'>
           Дії
          </th>
         </tr>
        </thead>
        <tbody>
         {templates.map(template => (
          <tr
           key={template.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='whitespace-nowrap px-6 py-2 font-medium'>
            {template.id}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>{template.category}</td>
           <td className='whitespace-nowrap px-6 py-2'>
            <JsonView
             data={JSON.parse(template.template)}
             shouldExpandNode={level => false}
             style={defaultStyles}
            />
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(template.created_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {handleDateObjectConversion(template.updated_at)}
           </td>
           <td className='whitespace-nowrap px-6 py-2 flex'>
            <TrashIcon className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out' />
            <PencilSquareIcon className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out' />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default Templates;
