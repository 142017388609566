import React, { useEffect, useState } from 'react';
import {
 CheckCircleIcon,
 MinusCircleIcon,
 PencilSquareIcon,
 TrashIcon,
} from '@heroicons/react/24/outline';
import axios from '../api/axios';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';

function MDBAlert(props) {
 return null;
}

MDBAlert.propTypes = {
 setOpen: PropTypes.func,
 delay: PropTypes.number,
 color: PropTypes.string,
 dismiss: PropTypes.bool,
 className: PropTypes.string,
 autohide: PropTypes.bool,
 open: PropTypes.bool,
 children: PropTypes.node,
};
const Staff = () => {
 const companyIdentifier = useSelector(
  state => state.users.currentUser.companyIdentifier,
 );
 const [stuff, setStuff] = useState([]);
 const [openSuccessAlert, setOpenSuccessAlert] = useState(false);

 const getStuff = () => {
  axios
   .get(`api/get-stuff/${companyIdentifier}`)
   .then(response => setStuff(response.data))
   .catch(function (error) {
    console.log(error);
   });
 };

 useEffect(() => {
  getStuff();
 }, []);

 const sallaryChangeHandler = (id, salary) => {
  const stuffCopy = stuff.map(item => {
   if (item.id === id) {
    return {
     ...item,
     salary: salary,
    };
   }
   return item;
  });
  setStuff(stuffCopy);

  setTimeout(() => {
   axios
    .post('/api/change-salary', { id, salary })
    .then(response => {
     if (response.status === 200) {
      getStuff();
     }
    })
    .catch(function (error) {
     console.log(error);
    });
  }, 2000);
 };

 return (
  <div className='container px-6 mx-auto max-w-7xl'>
   <h1 className='text-xl font-bold mt-6 mb-4'>Штатний розклад</h1>
   <div className='flex flex-col'>
    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
     <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
      <div className='overflow-hidden'>
       <table className='min-w-full text-left text-sm font-light'>
        <thead className='border-b font-medium dark:border-neutral-500'>
         <tr>
          <th scope='col' className='px-6 py-2'>
           Кваліфікація
          </th>
          <th scope='col' className='px-6 py-2'>
           Категорія
          </th>
          <th scope='col' className='px-6 py-2'>
           Науковий ступінь
          </th>
          <th scope='col' className='px-6 py-2 text-center'>
           Оклад
          </th>
          <th scope='col' className='px-6 py-2'>
           Дії
          </th>
         </tr>
        </thead>
        <tbody>
         {stuff.map(qualification => (
          <tr
           key={qualification.id}
           className='border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:border-neutral-500'
          >
           <td className='whitespace-nowrap px-6 py-2'>
            {qualification.qualification}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {qualification.category}
           </td>
           <td className='whitespace-nowrap px-6 py-2'>
            {qualification.scientific_degree}
           </td>
           <td className='whitespace-nowrap px-6 py-2 text-center'>
            <input
             type='number'
             className='h-10 text-center border rounded-lg'
             value={qualification.salary}
             onChange={e =>
              sallaryChangeHandler(qualification.id, e.target.value)
             }
            />
           </td>
           <td className='whitespace-nowrap px-6 py-2 flex items-center'>
            <TrashIcon className='w-6 h-6 text-danger cursor-pointer hover:scale-125 transition duration-500 ease-in-out' />
            <PencilSquareIcon className='ml-2 w-6 h-6 text-success cursor-pointer hover:scale-125 transition duration-500 ease-in-out' />
           </td>
          </tr>
         ))}
        </tbody>
       </table>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default Staff;
